import cn from 'classnames';
import isNil from 'lodash/isNil';
import { ButtonHTMLAttributes, ReactNode } from 'react';

import s from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  view?:
    | 'primary'
    | 'secondary'
    | 'ghost'
    | 'destructive'
    | 'tertiary1'
    | 'tertiary2'
    | 'tertiary3'
    | 'text';
}

export const Button = (props: ButtonProps) => {
  const { icon, size, view = 'primary', className, children, ...rest } = props;

  const rootClass = cn(
    s.root,
    {
      // size
      [s.small]: size === 'small',
      [s.medium]: size === 'medium' || isNil(size),
      [s.large]: size === 'large',
      // view
      [s.primary]: view === 'primary' || isNil(view),
      [s.secondary]: view === 'secondary',
      [s.ghost]: view === 'ghost',
      [s.destructive]: view === 'destructive',
      [s.tertiary1]: view === 'tertiary1',
      [s.tertiary2]: view === 'tertiary2',
      [s.tertiary3]: view === 'tertiary3',
      [s.text]: view === 'text'
    },
    className
  );

  return (
    <button {...rest} className={rootClass}>
      {icon && <div className={s.iconContainer}>{icon}</div>}
      {children}
    </button>
  );
};
