import {
  setupWalletSelector,
  AccountState,
  WalletSelector
} from '@near-wallet-selector/core';
// import { setupMeteorWallet } from '@near-wallet-selector/meteor-wallet';
import {
  setupModal,
  WalletSelectorModal
} from '@near-wallet-selector/modal-ui';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import { setupWalletConnect } from '@near-wallet-selector/wallet-connect';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  ReactNode
} from 'react';
import { distinctUntilChanged, map } from 'rxjs';

import { PreLoader } from '@app/components/PreLoader';
import { config } from '@app/config/appConfig';
import { WALLET_CONNECT_PROJECT_ID } from '@app/constants/wallets';

// todo - add account id to connect to
export const CONTRACT_ID = config.ACTIVATE_ACCOUNT_CONTRACTS.near;

interface WalletSelectorContextValue {
  selector: WalletSelector;
  modal: WalletSelectorModal;
  accounts: Array<AccountState>;
  accountId: string | null;
}

const WalletSelectorContext =
  React.createContext<WalletSelectorContextValue | null>(null);

export const WalletSelectorContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accounts, setAccounts] = useState<Array<AccountState>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const init = useCallback(async () => {
    const _selector = await setupWalletSelector({
      network: config.isTestnet ? 'testnet' : 'mainnet',
      debug: true,
      modules: [
        // setupMeteorWallet(),
        setupMyNearWallet(),
        setupWalletConnect({
          projectId: WALLET_CONNECT_PROJECT_ID,
          metadata: {
            name: 'Escher',
            description: '',
            url: '',
            icons: ['']
          }
        })
      ]
    });

    const _modal = setupModal(_selector, {
      contractId: CONTRACT_ID,
      theme: 'dark'
    });

    const state = _selector.store.getState();

    setAccounts(state.accounts);

    // this is added for debugging purpose only
    // for more information (https://github.com/near/wallet-selector/pull/764#issuecomment-1498073367)
    window.selector = _selector;
    window.modal = _modal;

    setSelector(_selector);
    setModal(_modal);
    setLoading(false);
  }, []);

  useEffect(() => {
    init().catch(err => {
      console.error(err);
      alert('Failed to initialise wallet selector');
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map(state => state.accounts),
        distinctUntilChanged()
      )
      .subscribe(nextAccounts => {
        console.info('Accounts Update', nextAccounts);

        setAccounts(nextAccounts);
      });

    const onHideSubscription = modal!.on('onHide', ({ hideReason }) => {
      console.info(`The reason for hiding the modal ${hideReason}`);
    });

    return () => {
      subscription.unsubscribe();
      onHideSubscription.remove();
    };
  }, [selector, modal]);

  const walletSelectorContextValue = useMemo<WalletSelectorContextValue>(
    () => ({
      selector: selector!,
      modal: modal!,
      accounts,
      accountId: accounts.find(account => account.active)?.accountId || null
    }),
    [selector, modal, accounts]
  );

  if (loading) {
    return <PreLoader />;
  }

  return (
    <WalletSelectorContext.Provider value={walletSelectorContextValue}>
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(WalletSelectorContext);

  if (!context) {
    throw new Error(
      'useWalletSelector must be used within a WalletSelectorContextProvider'
    );
  }

  return context;
}
