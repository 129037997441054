import cn from 'classnames';
import React from 'react';

import Ringfence from '@app/assets/images/logos/ringfence.svg';

import s from './PoweredBy.module.scss';

interface PoweredByProps {
  classes?: {
    root?: string;
    logo?: string;
  };
}

export const PoweredBy = (props: PoweredByProps) => {
  const { classes } = props;

  return (
    <div className={cn(s.root, classes?.root)}>
      powered by <Ringfence className={classes?.logo} />
    </div>
  );
};
