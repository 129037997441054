import cn from 'classnames';
import React from 'react';

import Escher from '@app/assets/images/logos/escher.svg';
import { PoweredBy } from '@app/components/PoweredBy';
import { ESCHER_LANDING } from '@app/constants/externalLinks';
import { LinkWrapper } from '@app/ui-kit';

import s from './PageHeaderLogo.module.scss';

interface PageHeaderLogoProps {
  classes?: {
    root?: string;
    escher?: string;
    powered?: string;
    ringfence?: string;
  };
}

export const PageHeaderLogo = (props: PageHeaderLogoProps) => {
  const { classes } = props;

  return (
    <LinkWrapper isExternal href={ESCHER_LANDING}>
      <div className={cn(s.root, classes?.root)}>
        <Escher className={cn(s.escher, classes?.escher)} />
        <PoweredBy
          classes={{
            root: classes?.powered,
            logo: cn(s.ringfenceLogo, classes?.ringfence)
          }}
        />
      </div>
    </LinkWrapper>
  );
};
