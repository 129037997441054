import { useEffect, useState } from 'react';

import { PageHeaderLogo } from '@app/components/PageHeaderLogo';
import { useActivationContext } from '@app/context/ActivationContext/useActivationContext';
import { useAuthContext } from '@app/context/AuthContext';

import s from './PreLoader.module.scss';

export const PreLoader = () => {
  const { init: authInit } = useAuthContext();
  const { init: activationInit } = useActivationContext();
  const init = authInit && activationInit;

  const [hide, setHide] = useState(false);

  useEffect(() => {
    // Hide with delay, show immediately
    if (init) {
      setTimeout(() => {
        setHide(init);
      }, 2000);
    } else {
      setHide(init);
    }
  }, [init]);

  if (hide) {
    return null;
  }

  return (
    <div className={s.root}>
      <PageHeaderLogo />
    </div>
  );
};
