import React, { PropsWithChildren } from 'react';

import { PreLoader } from '@app/components/PreLoader';
import { PUBLIC_ROUTES } from '@app/constants/routes';
import { useAuthContext } from '@app/context/AuthContext';

export const ChildrenAuthWrapper = (props: PropsWithChildren) => {
  const { children } = props;

  const { loggedIn } = useAuthContext();

  const { pathname } = window.location;
  const isPublicPage = PUBLIC_ROUTES.includes(pathname);

  if (isPublicPage || loggedIn) {
    return children;
  }

  return <PreLoader />;
};
