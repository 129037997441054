import { actionCreators, encodeSignedDelegate } from '@near-js/transactions';
import { Account } from 'near-api-js';

import { config } from '@app/config/appConfig';
import { fetchWrapper } from '@app/utils/fetchWrapper';

export interface MintCnftProps {
  imageId: number;
  source: 'upload' | 'generate';
  assetId: string;
  imageUrl: string;
  username: string;
  modelTrainingEnabled: boolean;
}

interface Input extends MintCnftProps {
  nearAccount: Account;
}

function getNftId(imageId: number) {
  const digits = 8;
  const min = Math.pow(10, digits - 1);
  const max = Math.pow(10, digits) - 1;
  const randomId = Math.floor(min + Math.random() * (max - min + 1));

  return `cnft${randomId}${imageId}`;
}

export async function mintCNftUtil(input: Input) {
  const {
    nearAccount,
    imageId,
    source,
    assetId,
    imageUrl,
    username,
    modelTrainingEnabled
  } = input;

  const { data: collections } = await fetchWrapper<any[]>('/api', {
    method: 'POST',
    body: JSON.stringify({
      opts: {
        url: `/api/org/collections/get-links-collections-by-images?${source === 'upload' ? 'upload_image_ids' : 'generated_image_ids'}=${imageId}`,
        method: 'GET'
      }
    })
  });
  const collection = collections?.[0];

  if (!collection) {
    throw new Error('Collection not found');
  }

  if (!collection.minted) {
    throw new Error('Collection not minted');
  }

  const tokenId = getNftId(imageId);

  const title = `${collection.title} ${tokenId}`;

  const action = actionCreators.functionCall(
    'nft_mint',
    {
      token_id: tokenId,
      metadata: {
        title,
        description:
          source === 'upload'
            ? `Image by ${username} from escher.ai`
            : `This image was created on escher.ai by ${username}`,
        media: imageUrl,
        extra: JSON.stringify({ asset_id: assetId, asset_type: 'image' })
      },
      rnft_token_id: collection.rnftId,
      receiver_id: collection.tokenBoundAccount,
      model_training_enabled: modelTrainingEnabled
    },
    BigInt('300000000000000'),
    BigInt('11000000000000000000000')
  );

  const signedDelegate = await nearAccount.signedDelegate({
    actions: [action],
    blockHeightTtl: 1000,
    receiverId: config.NFT_CONTRACTS.cnft
  });

  const txSignedDelegateBase64 = Buffer.from(
    encodeSignedDelegate(signedDelegate)
  ).toString('base64');

  const { success: successMint } = await fetchWrapper('/api', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        txName: 'mint-cnft',
        txSignedDelegateBase64
      },
      opts: {
        url: '/api/wallet/near/send-transaction',
        method: 'POST'
      }
    })
  });

  if (!successMint) {
    return { success: false, data: { tokenId } };
  }

  return new Promise<{ success: boolean; data: { tokenId: string } }>(
    resolve => {
      const loadCnft = async () => {
        const { data, success } = await fetchWrapper<{ minted: boolean }>(
          '/api',
          {
            method: 'POST',
            body: JSON.stringify({
              opts: {
                url: `/api/org/get-images/${source === 'upload' ? 'uploaded' : 'generated'}/${imageId}`,
                method: 'GET'
              }
            })
          },
          { skipToast: true }
        );

        if (success && data?.minted) {
          clearInterval(intervalId);
          resolve({ success: true, data: { tokenId } });
        }
      };
      const intervalId = setInterval(loadCnft, 5000);

      loadCnft();
    }
  );
}
