import { createContext } from 'react';

import { UserInfo } from '@app/types/user';
import { Result } from '@app/utils/fetchWrapper';

import { MintCnftProps } from './utils/mintCNftUtil';

export interface AuthContextI {
  init: boolean;
  loggedIn: boolean;
  logout: () => Promise<void>;
  mintRnft: (title: string, userId: number) => Promise<Result<unknown>>;
  mintCnft: (props: MintCnftProps) => Promise<Result<any>>;
  loginWithOTP: (token: string) => Promise<void>;
  loginWithDiscord: () => Promise<void>;
  loadUserInfo: () => Promise<void>;
  userInfo: UserInfo | null;
}

export const AuthContext = createContext<AuthContextI>({
  init: false,
  loggedIn: false,
  logout: () => Promise.resolve(),
  mintRnft: () => Promise.resolve({ success: true }),
  mintCnft: (): Promise<Result<{ tokenId: string }>> =>
    Promise.resolve({ success: true }),
  loginWithOTP: () => Promise.resolve(),
  loginWithDiscord: () => Promise.resolve(),
  loadUserInfo: () => Promise.resolve(),
  userInfo: null
});
