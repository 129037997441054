import cn from 'classnames';
import React from 'react';

import icons from 'public/icons';

import s from './Icon.module.scss';

export type IconName = keyof typeof icons;

interface IconProps extends React.HTMLAttributes<SVGElement> {
  width?: number;
  height?: number;
  className?: string;
  name: IconName;
  onClick?: () => void;
}

export const Icon = (props: IconProps) => {
  const { width, height, className, name, onClick, ...rest } = props;

  const SVG: React.ComponentClass<any> = icons[name];

  return (
    <SVG
      className={cn(s.root, className)}
      onClick={onClick}
      {...rest}
      style={{
        width,
        height
      }}
    />
  );
};
