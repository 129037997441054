import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { WagmiProvider } from 'wagmi';

import { PreLoader } from '@app/components/PreLoader';
import { wagmiConfig } from '@app/config/wallet';
import { ActivationContextProvider } from '@app/context/ActivationContext/ActivationContextProvider';
import { AuthContextProvider } from '@app/context/AuthContext';
import { UserContextProvider } from '@app/context/UserContext';
import { WalletSelectorContextProvider } from '@app/context/WalletSelectorContext/WalletSelectorContext';
import { ModalProvider } from '@app/ui-kit/Modal';

import { ChildrenAuthWrapper } from './components/ChildrenAuthWrapper';

interface AppContextWrapperProps {
  children: React.ReactNode;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export const AppContextWrapper = ({ children }: AppContextWrapperProps) => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme()}>
          <WalletSelectorContextProvider>
            <AuthContextProvider>
              <UserContextProvider>
                <ModalProvider>
                  <ActivationContextProvider>
                    <ChildrenAuthWrapper>
                      <PreLoader />
                      {children}
                    </ChildrenAuthWrapper>
                  </ActivationContextProvider>
                </ModalProvider>
              </UserContextProvider>
            </AuthContextProvider>
          </WalletSelectorContextProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
