import cn from 'classnames';
import React, {
  InputHTMLAttributes,
  forwardRef,
  useRef,
  useCallback,
  ReactNode
} from 'react';

import { useMergedRefs } from '@app/hooks/useMergedRefs';
import { ErrorMessage } from '@app/ui-kit/ErrorMessage';

import s from './Input.module.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  classes?: {
    root?: string;
    input?: string;
  };
  icon?: ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const { icon, error, classes, disabled, ...rest } = props;

    const localRef = useRef<HTMLInputElement>(null);

    const focus = useCallback(() => localRef.current?.focus(), []);

    const mergedRef = useMergedRefs(ref, localRef);

    const rootClass = cn(
      s.root,
      {
        [s.error]: !!error,
        [s.disabled]: disabled
      },
      classes?.root
    );

    return (
      <>
        <div onClick={focus} className={rootClass}>
          <input
            ref={mergedRef}
            {...rest}
            className={cn(classes?.input, s.input)}
          />
          {icon}
        </div>
        <ErrorMessage>{error}</ErrorMessage>
      </>
    );
  }
);
