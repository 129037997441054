import cn from 'classnames';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import { Delimiter } from '@app/ui-kit';

import s from './Tabs.module.scss';

interface TabsProps {
  id?: string;
  selected: number;
  tabs: ReactNode[];
  classes?: {
    root?: string;
    tabs?: string;
  };
  onTabClick: (i: number) => void;
}

export const Tabs = (props: TabsProps) => {
  const { id, tabs, selected, classes, onTabClick } = props;

  const { query, replace } = useRouter();

  const handleTabChange = (index: number) => {
    if (id) {
      replace(
        {
          query: {
            ...query,
            [id]: index
          }
        },
        undefined,
        { shallow: true }
      );
    }

    onTabClick(index);
  };

  return (
    <div className={classes?.root}>
      <div className={cn(s.tabs, classes?.tabs)}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={cn(s.tab, {
              [s.selected]: index === selected
            })}
            onClick={() => handleTabChange(index)}
          >
            {tab}
          </div>
        ))}
      </div>
      <Delimiter />
    </div>
  );
};
