import cn from 'classnames';
import { PropsWithChildren } from 'react';

import s from './Chip.module.scss';
import { ChipType } from './types';

interface RfChipInterface extends PropsWithChildren {
  type?: ChipType;
  className?: string;
}

export const Chip = (props: RfChipInterface) => {
  const { type, children, className } = props;

  return (
    <div
      className={cn(
        s.root,
        {
          [s.green]: type === 'green',
          [s.grey]: type === 'grey',
          [s.blue]: type === 'blue',
          [s.purple]: type === 'purple',
          [s.yellow]: type === 'yellow'
        },
        className
      )}
    >
      {children}
    </div>
  );
};
