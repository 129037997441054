import isString from 'lodash/isString';
import { toast } from 'react-toastify';

export interface Result<T> {
  data?: T;
  success: boolean;
}

interface RequestOpts {
  error?: string;
  skipToast?: boolean;
}

function getError(optError?: string, responseError?: unknown) {
  if (optError) {
    return optError;
  }

  // sometimes it comes as array ¯\_(ツ)_/¯
  if (responseError && isString(responseError)) {
    return responseError;
  }

  return 'Error occurred. Try again later.';
}

export const fetchWrapper = async <T>(
  input: RequestInfo | URL,
  init?: RequestInit,
  opts?: RequestOpts
): Promise<Result<T>> => {
  const { error, skipToast } = opts || {};

  const response = await fetch(input, init);

  const result = await response.json();

  if (response.ok) {
    return { data: result, success: true };
  }

  if (!skipToast && response.status !== 401) {
    toast(getError(error, result?.error?.message), {
      type: 'error'
    });
  }

  return {
    success: false
  };
};
