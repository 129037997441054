/* eslint-disable */
// This file was generated by script
// this file is used to generate icon names from files within this directory
// icons are used in Icon component

import aiApproved from './ai-approved.svg';
import arrowAgain from './arrow-again.svg';
import arrowLeft from './arrow-left.svg';
import arrowRight from './arrow-right.svg';
import back from './back.svg';
import bug from './bug.svg';
import chainArbitrum from './chainArbitrum.svg';
import chainNear from './chainNear.svg';
import check from './check.svg';
import chevronDown from './chevronDown.svg';
import clock from './clock.svg';
import copy from './copy.svg';
import cross from './cross.svg';
import crown from './crown.svg';
import discord from './discord.svg';
import dots from './dots.svg';
import doubleDown from './double-down.svg';
import empty from './empty.svg';
import error from './error.svg';
import escherPoint from './escher-point.svg';
import eth from './eth.svg';
import explore from './explore.svg';
import filledStar from './filled-star.svg';
import folder from './folder.svg';
import frame from './frame.svg';
import gear from './gear.svg';
import generate from './generate.svg';
import google from './google.svg';
import heartOutline from './heart-outline.svg';
import heartSolid from './heart-solid.svg';
import image from './image.svg';
import info from './info.svg';
import inviteUser from './inviteUser.svg';
import leaderboard from './leaderboard.svg';
import loadingAlt from './loading-alt.svg';
import loading from './loading.svg';
import lock from './lock.svg';
import logout from './logout.svg';
import menu from './menu.svg';
import moreDots from './more-dots.svg';
import music from './music.svg';
import plus from './plus.svg';
import pointsOutline from './points-outline.svg';
import pointsFilled from './pointsFilled.svg';
import progress5 from './progress5.svg';
import question from './question.svg';
import reload from './reload.svg';
import sheet from './sheet.svg';
import socialFacebook from './socialFacebook.svg';
import socialInstagram from './socialInstagram.svg';
import socialLinkedin from './socialLinkedin.svg';
import socialTelegram from './socialTelegram.svg';
import socialTiktok from './socialTiktok.svg';
import socialWatsup from './socialWatsup.svg';
import socialX from './socialX.svg';
import socialYoutube from './socialYoutube.svg';
import star from './star.svg';
import style from './style.svg';
import tokenUsdc from './tokenUsdc.svg';
import tokenUsdt from './tokenUsdt.svg';
import trash from './trash.svg';
import upload from './upload.svg';
import video from './video.svg';

const icons = {
aiApproved,
arrowAgain,
arrowLeft,
arrowRight,
back,
bug,
chainArbitrum,
chainNear,
check,
chevronDown,
clock,
copy,
cross,
crown,
discord,
dots,
doubleDown,
empty,
error,
escherPoint,
eth,
explore,
filledStar,
folder,
frame,
gear,
generate,
google,
heartOutline,
heartSolid,
image,
info,
inviteUser,
leaderboard,
loadingAlt,
loading,
lock,
logout,
menu,
moreDots,
music,
plus,
pointsOutline,
pointsFilled,
progress5,
question,
reload,
sheet,
socialFacebook,
socialInstagram,
socialLinkedin,
socialTelegram,
socialTiktok,
socialWatsup,
socialX,
socialYoutube,
star,
style,
tokenUsdc,
tokenUsdt,
trash,
upload,
video,
};

export default icons;
