import { WalletProfile } from '@app/types/user';
import { fetchWrapper } from '@app/utils/fetchWrapper';

export async function fetchWalletProfile(
  setWalletProfile: (walletProfile: WalletProfile) => void
) {
  const { data, success } = await fetchWrapper<WalletProfile>('/api', {
    method: 'POST',
    body: JSON.stringify({
      opts: {
        method: 'GET',
        url: '/api/wallet/my-profile'
      }
    })
  });

  if (success && data) {
    setWalletProfile(data);
  }
}
