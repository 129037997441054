import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { cookieStorage, createStorage } from 'wagmi';
import { sepolia, arbitrum, mainnet } from 'wagmi/chains';

import { config } from '@app/config/appConfig';
import { WALLET_CONNECT_PROJECT_ID } from '@app/constants/wallets';

import '@rainbow-me/rainbowkit/styles.css';

const chains = config.isTestnet
  ? ([
      {
        ...sepolia,
        rpcUrls: {
          default: {
            http: ['https://rpc2.sepolia.org']
          }
        }
      }
    ] as const)
  : ([arbitrum, mainnet] as const);

export const wagmiConfig = getDefaultConfig({
  appName: 'Escher',
  projectId: WALLET_CONNECT_PROJECT_ID,
  chains,
  ssr: true, // If your dApp uses server side rendering (SSR),
  storage: createStorage({
    storage: cookieStorage
  })
});
