import { arbitrum, mainnet, sepolia } from 'wagmi/chains';

import { Token } from '@app/types/token';

type ApplicationConfig = Record<
  string,
  {
    isTestnet: boolean;
    ACTIVATE_ACCOUNT_CONTRACTS: Record<string, string>;
    TOKENS: Record<string, Record<string, Token>>;
    NFT_CONTRACTS: {
      rnft: string;
      cnft: string;
    };
    nearblocksUrl: string;
  }
>;

export const appEnv =
  process.env.NEXT_PUBLIC_ENV === 'prod' ? 'MAINNET' : 'TESTNET';

const APP_CONFIG: ApplicationConfig = {
  TESTNET: {
    isTestnet: true,
    ACTIVATE_ACCOUNT_CONTRACTS: {
      [sepolia.id]: '0xf28D28323E25384B836D76A2520f6C9845ea2229',
      [arbitrum.id]: '0x7d0F42F6f867AdE94a193EC378c550dC6698c225',
      near: 'rpayment.testnet'
    },
    NFT_CONTRACTS: {
      rnft: 'rnft-dev.testnet',
      cnft: 'cnft-dev.testnet'
    },
    TOKENS: {
      near: {
        usdt: { address: '', decimals: 6, symbol: 'USDT' },
        usdc: {
          address:
            '3e2210e1184b45b64c8a434c0a7e7b23cc04ea7eb7a6c3c32520d03d4afcb8af',
          decimals: 6,
          symbol: 'USDC'
        }
      },
      [sepolia.id]: {
        usdt: {
          address: '0xaA8E23Fb1079EA71e0a56F48a2aA51851D8433D0',
          decimals: 6,
          symbol: 'USDT'
        },
        usdc: {
          address: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
          decimals: 6,
          symbol: 'USDC'
        }
      },
      [mainnet.id]: {
        usdt: {
          address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
          decimals: 6,
          symbol: 'USDT'
        },
        usdc: {
          address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
          decimals: 6,
          symbol: 'USDC'
        }
      },
      [arbitrum.id]: {
        usdt: {
          address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
          decimals: 6,
          symbol: 'USDT'
        },
        usdc: {
          address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
          decimals: 6,
          symbol: 'USDC'
        }
      }
    },
    nearblocksUrl: 'https://testnet.nearblocks.io'
  },
  MAINNET: {
    isTestnet: false,
    ACTIVATE_ACCOUNT_CONTRACTS: {
      [mainnet.id]: '0x2c493AA962cc493F496c46C16575e0A81b299fC1',
      [arbitrum.id]: '0x7d0F42F6f867AdE94a193EC378c550dC6698c225',
      near: 'pay.rfence.near'
    },
    NFT_CONTRACTS: {
      rnft: 'rnft.rfence.near',
      cnft: 'cnft.rfence.near'
    },
    TOKENS: {
      near: {
        usdt: {
          address:
            'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near',
          decimals: 6,
          symbol: 'USDT'
        },
        usdc: {
          address:
            '17208628f84f5d6ad33f0da3bbbeb27ffcb398eac501a31bd6ad2011e36133a1',
          decimals: 6,
          symbol: 'USDC'
        }
      },
      [mainnet.id]: {
        usdt: {
          address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
          decimals: 6,
          symbol: 'USDT'
        },
        usdc: {
          address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
          decimals: 6,
          symbol: 'USDC'
        }
      },
      [arbitrum.id]: {
        usdt: {
          address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
          decimals: 6,
          symbol: 'USDT'
        },
        usdc: {
          address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
          decimals: 6,
          symbol: 'USDC'
        }
      },
      [sepolia.id]: {
        usdt: {
          address: '0xaA8E23Fb1079EA71e0a56F48a2aA51851D8433D0',
          decimals: 6,
          symbol: 'USDT'
        },
        usdc: {
          address: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
          decimals: 6,
          symbol: 'USDC'
        }
      }
    },
    nearblocksUrl: 'https://nearblocks.io'
  }
};

export const config = APP_CONFIG[appEnv];
