export const META_TAGS_DEFAULT_CONFIG = {
  twitterCardType: 'summary_large_image',
  twitterCreator: '',
  twitterImageAlt: '',
  twitterCite: '',
  title: 'Escher - Where Web3 Meets Generative AI',
  description:
    'Harness the power of AI to create more dynamic, monetizable content for your audience.',
  url: '',
  image:
    'https://cdn.prod.website-files.com/65d5e8e5e2d7b7eff3b4f1f6/66cdc56937c242b2a47f3b9d_9067b438e64b30d66447cc3f1b586af0.png'
};
