import { useRouter } from 'next/router';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { ACTIVATE_ROUTE, CLAIM_RNFT_ROUTE } from '@app/constants/routes';
import { useAuthContext } from '@app/context/AuthContext/useAuthContext';
import { ACTIVATE_ENDPOINTS } from '@app/features/activate/constants/endpoints';
import { MintingProcess } from '@app/features/vault/components/MintingProcess';
import { SuccessfullyMintedDialog } from '@app/features/vault/components/SuccessfullyMintedDialog';
import { Subscription } from '@app/types/user';
import { useModal } from '@app/ui-kit/Modal';
import { fetchWrapper } from '@app/utils/fetchWrapper';

import { ActivationContext } from './ActivationContext';

interface ActivationContextProviderProps extends PropsWithChildren {}

export const ActivationContextProvider = (
  props: ActivationContextProviderProps
) => {
  const { children } = props;

  const { push } = useRouter();

  const { loggedIn, userInfo, mintRnft } = useAuthContext();
  const [init, setInit] = useState(false);
  const [activated, setActivated] = useState(false);

  const [showMintingProcessModal] = useModal(MintingProcess, {
    title: 'Minting rNFT',
    text: 'Allow us a moment to create your rNFT'
  });

  const toggleMintingProcessModal = useCallback(() => {
    showMintingProcessModal();
  }, [showMintingProcessModal]);

  const [showSuccessfullyMintedModal] = useModal(SuccessfullyMintedDialog, {
    text: 'Your first rNFT has been successfully minted!'
  });

  const openSuccessfullyMintedModal = useCallback(() => {
    showSuccessfullyMintedModal();
  }, [showSuccessfullyMintedModal]);

  const getPreAlphaRnftName = useCallback(async (): Promise<
    string | undefined
  > => {
    const { data: preAlphaCollection } = await fetchWrapper<{
      title: string;
    }>('/api', {
      method: 'POST',
      body: JSON.stringify({
        opts: {
          url: '/api/org/collections/pre-alpha-collection',
          method: 'GET'
        }
      })
    });

    return preAlphaCollection?.title;
  }, []);

  const checkUserSubscriptions = useCallback(async (): Promise<boolean> => {
    if (userInfo?.subscriptions && userInfo?.subscriptions.length) {
      return true;
    }

    const { data: subscriptions } = await fetchWrapper<Subscription[]>('/api', {
      method: 'POST',
      body: JSON.stringify({
        opts: {
          url: ACTIVATE_ENDPOINTS.getMySubscriptions,
          method: 'GET'
        }
      })
    });

    if (subscriptions && subscriptions.length) {
      return true;
    }

    return false;
  }, [userInfo]);

  const getRnftMintStatus = useCallback(async (): Promise<
    'pending' | 'success' | 'none'
  > => {
    if (userInfo?.rnftMinted) {
      return 'success';
    }

    const { data: transactions } = await fetchWrapper<
      {
        name: string;
        status: string;
      }[]
    >('/api', {
      method: 'POST',
      body: JSON.stringify({
        opts: {
          url: '/api/wallet/near/my-transactions',
          method: 'GET'
        }
      })
    });

    const mintTx = transactions?.find(
      t =>
        t.name === 'mint-rnft' &&
        (t.status === 'success' || t.status === 'pending')
    );

    if (mintTx) {
      return mintTx.status as 'pending' | 'success';
    }

    return 'none';
  }, [userInfo]);

  const waitOnRnftMint = useCallback(async () => {
    return new Promise<{ success: boolean }>(resolve => {
      toggleMintingProcessModal();

      const intervalId = setInterval(async () => {
        const status = await getRnftMintStatus();

        if (status === 'none') {
          clearInterval(intervalId);
          toggleMintingProcessModal();
          resolve({ success: false });
        } else if (status === 'success') {
          clearInterval(intervalId);
          toggleMintingProcessModal();

          setTimeout(() => {
            openSuccessfullyMintedModal();
            resolve({ success: true });
          }, 100);
        }
      }, 5000);
    });
  }, [
    getRnftMintStatus,
    toggleMintingProcessModal,
    openSuccessfullyMintedModal
  ]);

  const mintPreAlphaRnft = useCallback(async (): Promise<{
    success: boolean;
  }> => {
    const preAlphaRnftName = await getPreAlphaRnftName();

    if (!preAlphaRnftName || !userInfo) {
      return { success: false };
    }

    toggleMintingProcessModal();

    await fetchWrapper<{
      title: string;
    }>(
      '/api',
      {
        method: 'POST',
        body: JSON.stringify({
          opts: {
            url: '/api/wallet/activate-my-wallet',
            method: 'POST'
          }
        })
      },
      { skipToast: true }
    );

    const { success } = await mintRnft(preAlphaRnftName, userInfo.userId).catch(
      () => {
        return { success: false };
      }
    );

    toggleMintingProcessModal();

    if (success) {
      setTimeout(() => {
        openSuccessfullyMintedModal();
      }, 100);
    }

    return { success };
  }, [
    userInfo,
    mintRnft,
    toggleMintingProcessModal,
    openSuccessfullyMintedModal,
    getPreAlphaRnftName
  ]);

  const initActivationState = async () => {
    try {
      const { pathname } = window.location;
      const hasActiveSubscription = await checkUserSubscriptions();

      if (!hasActiveSubscription) {
        if (pathname === CLAIM_RNFT_ROUTE) {
          setInit(true);

          return;
        }

        const preAlphaRnftName = await getPreAlphaRnftName();

        setInit(true);

        if (!preAlphaRnftName) {
          push(CLAIM_RNFT_ROUTE);

          return;
        } else if (pathname !== ACTIVATE_ROUTE) {
          push(ACTIVATE_ROUTE);

          return;
        }
      } else {
        const mintStatus = await getRnftMintStatus();

        setInit(true);

        if (mintStatus === 'success') {
          setActivated(true);

          return;
        }

        if (mintStatus === 'pending') {
          const { success } = await waitOnRnftMint();

          setActivated(success);

          return;
        }

        if (mintStatus === 'none') {
          const { success } = await mintPreAlphaRnft();

          setActivated(success);

          return;
        }
      }
    } catch (error) {
      console.log('err');
      console.error(error);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      if (userInfo) {
        initActivationState();
      }
    } else {
      setInit(true);
    }
  }, [loggedIn, userInfo]);

  const contextValue = useMemo(() => {
    return {
      init,
      activated
    };
  }, [init, activated]);

  return (
    <ActivationContext.Provider value={contextValue}>
      {children}
    </ActivationContext.Provider>
  );
};
