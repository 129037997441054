import { NextPage } from 'next';
import Script from 'next/script';
import process from 'process';
import React, { ReactElement, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMount } from 'react-use';

import Metadata from '@app/components/Metadata';
import { AppContextWrapper } from '@app/context/AppContextWrapper';

import type { AppProps } from 'next/app';

import '@app/styles/global.css';
import '@app/styles/index.scss';
import '@near-wallet-selector/modal-ui/styles.css';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({ Component, pageProps, router }: AppPropsWithLayout) => {
  const gaTag = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TOKEN;

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page);

  useMount(() => {
    document.addEventListener('contextmenu', event => event.preventDefault());
  });

  return (
    <>
      <Metadata {...pageProps?.metadata} />
      <ToastContainer theme="dark" />
      <AppContextWrapper>
        {getLayout(<Component {...pageProps} key={router.asPath} />)}
      </AppContextWrapper>
      {gaTag && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTag}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${gaTag}');
            `}
          </Script>
        </>
      )}
    </>
  );
};

export default App;
