import isString from 'lodash/isString';
import { useCallback, useEffect, useState } from 'react';

export function useTabs(initTab?: number, id?: string) {
  const [tab, setTab] = useState(initTab || 0);

  useEffect(() => {
    if (id) {
      const url = new URL(window.location.href);
      const selectedTab = url.searchParams.get(id);

      if (selectedTab && isString(selectedTab)) {
        setTab(parseInt(selectedTab));
      }
    }
  }, []);

  const onTabClick = useCallback((index: number) => {
    setTab(index);
  }, []);

  return {
    tab,
    onTabClick
  };
}
