import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Icon } from '@app/ui-kit';

import s from './ErrorMessage.module.scss';

interface ErrorMessageProps extends PropsWithChildren {
  className?: string;
}

export const ErrorMessage = (props: ErrorMessageProps) => {
  const { children, className } = props;

  if (!children) {
    return null;
  }

  return (
    <div className={cn(s.root, className)}>
      <Icon name="error" className={s.icon} />
      <span>{children}</span>
    </div>
  );
};
