import cn from 'classnames';

import s from './Delimiter.module.scss';

interface DelimiterProps {
  className?: string;
}

export const Delimiter = (props: DelimiterProps) => {
  const { className } = props;

  return <div className={cn(s.root, className)} />;
};
