export const TWITTER = 'https://x.com/RingfenceAI';
export const TELEGRAM = 'https://t.me/RingfenceAICommunity';
export const LINKEDIN = 'https://www.linkedin.com/company/ringfenceai';
export const DISCORD = 'https://discord.com/invite/ringfenceai';

export const TERMS_OF_USE = 'https://escher.ringfence.ai/terms';
export const PRIVACY_POLICY = 'https://escher.ringfence.ai/privacy-policy';

export const ESCHER_LANDING = 'http://escher.ringfence.ai';
export const WHAT_IS_RNFT = 'https://escher.ringfence.ai/blog/litepaper';

export const SUBMIT_A_BUG =
  'https://airtable.com/appZWP0UGDU7yZoJp/pagDeGaDg0A9UCAIZ/form';
export const ASK_A_QUESTION =
  'https://airtable.com/appZhUoDdxKfurDOK/pagndh7NlNpofUp3N/form';
