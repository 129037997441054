import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from '@web3auth/base';
import { CommonPrivateKeyProvider } from '@web3auth/base-provider';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';

const web3AuthClientId =
  'BGDcTOVanLe7Sz-gER_CIgQHIoE2sTCaMf3kEe58tQVlVR3y5k7NgadVUnJ_mSS0GGjggue2wcGuq-Nz5Lcf4xE';

const DISCORD_CLIENT_ID = '1274620596046528512';

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.OTHER,
  chainId: '0x4e454153',
  rpcTarget: 'https://testnet.aurora.dev',
  // Avoid using public rpcTarget in production.
  displayName: 'Near',
  blockExplorerUrl: 'https://explorer.testnet.aurora.dev',
  ticker: 'NEAR',
  tickerName: 'NEAR'
};

const privateKeyProvider = new CommonPrivateKeyProvider({
  config: { chainConfig }
});

const web3auth = new Web3AuthNoModal({
  clientId: web3AuthClientId,
  privateKeyProvider,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET
});

const openloginAdapter = new OpenloginAdapter({
  adapterSettings: {
    uxMode: 'redirect', // redirect or popup
    loginConfig: {
      discord: {
        verifier: 'ringfence-dev-discord', // Pass the Verifier name here
        typeOfLogin: 'discord', // Pass on the login provider of the verifier you've created
        clientId: DISCORD_CLIENT_ID // Pass on the Discord `Client ID` here
      },
      jwt: {
        verifier: 'ringfence-dev-otp', // name of the verifier created on Web3Auth Dashboard
        typeOfLogin: 'jwt',
        clientId: web3AuthClientId
      }
    }
  },
  privateKeyProvider
});

web3auth.configureAdapter(openloginAdapter);

export { web3auth, DISCORD_CLIENT_ID };
