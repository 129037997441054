import { createContext } from 'react';

import { Rewards, User, WalletProfile, Subscription } from '@app/types/user';

export interface UserContextI {
  user?: User;
  setUser: (user: User) => void;
  walletProfile?: WalletProfile;
  setWalletProfile: (walletProfile: WalletProfile) => void;
  refetchRewards: () => Promise<void>;
  refetchWalletProfile: () => Promise<void>;
  refetchCreditBalance: () => Promise<void>;
  refetchSubscription: () => Promise<void>;
  username?: string;
  rewards?: Rewards;
  balance: string;
  subscriptions?: Subscription[];
}

export const UserContext = createContext<UserContextI>({
  balance: '0',
  username: '',
  user: undefined,
  rewards: undefined,
  subscriptions: [],
  setUser: () => {},
  walletProfile: undefined,
  setWalletProfile: () => {},
  refetchRewards: () => Promise.resolve(),
  refetchSubscription: () => Promise.resolve(),
  refetchWalletProfile: () => Promise.resolve(),
  refetchCreditBalance: () => Promise.resolve()
});
