import { actionCreators, encodeSignedDelegate } from '@near-js/transactions';
import { Account } from 'near-api-js';

import { config } from '@app/config/appConfig';
import { fetchWrapper } from '@app/utils/fetchWrapper';
import { getUiBaseUrl } from '@app/utils/getUiBaseUrl';

interface Input {
  title: string;
  userId: number;
  accountId: string;
  nearAccount: Account;
}

function getImgUrl() {
  const base = getUiBaseUrl();

  return `${base}/images/rnft.png`;
}

function getNftId(userId: number) {
  const digits = 7 - userId.toString().length;
  const min = Math.pow(10, digits - 1);
  const max = Math.pow(10, digits) - 1;
  const randomId = Math.floor(min + Math.random() * (max - min + 1));

  return `rnft${randomId}${userId}`;
}

export async function mintRNftUtil(input: Input) {
  const { title, userId, accountId, nearAccount } = input;

  const tokenId = getNftId(userId);

  const action = actionCreators.functionCall(
    'nft_mint',
    {
      token_id: tokenId,
      metadata: {
        title,
        description: `"${title}" ESCHER rNFT - the digital folders to hold your creations.`,
        media: getImgUrl(),
        extra: JSON.stringify({ asset_id: tokenId, asset_type: 'vault' })
      },
      receiver_id: accountId
    },
    BigInt('300000000000000'),
    BigInt('11000000000000000000000')
  );

  const signedDelegate = await nearAccount.signedDelegate({
    actions: [action],
    blockHeightTtl: 1000,
    receiverId: config.NFT_CONTRACTS.rnft
  });

  const txSignedDelegateBase64 = Buffer.from(
    encodeSignedDelegate(signedDelegate)
  ).toString('base64');

  await fetchWrapper('/api', {
    method: 'POST',
    body: JSON.stringify({
      data: {
        txName: 'mint-rnft',
        txSignedDelegateBase64
      },
      opts: {
        url: '/api/wallet/near/send-transaction',
        method: 'POST'
      }
    })
  });

  return new Promise<{ success: boolean }>(resolve => {
    const loadRnft = async () => {
      const { success } = await fetchWrapper(
        '/api',
        {
          method: 'POST',
          body: JSON.stringify({
            opts: {
              url: `/api/org/collections/get-by-rnft/${tokenId}`,
              method: 'GET'
            }
          })
        },
        { skipToast: true }
      );

      if (success) {
        clearInterval(intervalId);
        resolve({ success: true });
      }
    };
    const intervalId = setInterval(loadRnft, 5000);

    loadRnft();
  });
}
