import React, { FC } from 'react';

import { Button, Icon } from '@app/ui-kit';
import { Modal } from '@app/ui-kit/Modal';

import s from './SuccessfullyMintedDialog.module.scss';

interface SuccessfullyMintedDialogProps {
  isOpen: boolean;
  onClose: () => void;
  text?: string;
}

export const SuccessfullyMintedDialog: FC<SuccessfullyMintedDialogProps> = ({
  isOpen,
  onClose,
  text = 'Your cNFT has been successfully minted.'
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseIcon
      className={s.root}
      overlayClassName={s.overlay}
      size="sm"
    >
      <div className={s.image}>
        <Icon name="check" className={s.icon} />
      </div>
      <p className={s.title}>Congratulations!</p>
      <p className={s.text}>{text}</p>
      <div className={s.footer}>
        <Button onClick={onClose} className={s.btn}>
          Ok
        </Button>
      </div>
    </Modal>
  );
};
