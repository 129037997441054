export const PROFILE_ROUTE = '/profile';
export const GENERATE_ROUTE = '/generate';
export const EXPLORE_ROUTE = '/explore';
// export const EARN_ROUTE = '/earn';
export const EARN_ROUTE = '/earn/3';
export const VAULT_ROUTE = '/vault';
export const LEADERBOARD_ROUTE = '/leaderboard';
export const PLANS_BILLING_ROUTE = '/billing';
export const CREDITS_ROUTE = '/credits';
export const HELP_ROUTE = '/help';
export const CREATE_RNFT_ROUTE = '/create-rnft';
export const LOGIN_ROUTE = '/login';
export const UI_KIT_ROUTE = '/ui-kit';
export const STAY_TUNED_ROUTE = '/stay-tuned';
export const CLAIM_RNFT_ROUTE = '/claim-rnft';
export const ACTIVATE_ROUTE = '/activate';
export const FULL_ROUTE = '/full';
export const SHARE_ROUTE = '/share';
export const INVOICES_ROUTE = '/invoices';

export const PUBLIC_ROUTES = [LOGIN_ROUTE, UI_KIT_ROUTE, SHARE_ROUTE];
