import { FunctionComponent, useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ModalContext, IModal } from './ModalContext';

type OnCloseParameters<T extends IModal> = Parameters<T['onClose']>;

type UseModalResult<T extends IModal> = [
  (props?: Partial<T>) => Promise<OnCloseParameters<T>>,
  (modalId: string) => void,
  FunctionComponent<Partial<T>>
];

type DefaultProps = {
  id?: string;
};

export const useModal = <P extends IModal>(
  Component: FunctionComponent<P>,
  initialProps?: Partial<P>
): UseModalResult<P> => {
  const context = useContext(ModalContext);

  const ModalComponent: FunctionComponent<
    Partial<P> & DefaultProps
  > = modalProps => {
    const id = modalProps?.id ?? uuidv4();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onCloseModal = (...args: OnCloseParameters<P>) => {
      context.hideModal(id);
    };

    const props = {
      ...initialProps,
      ...modalProps
    };

    return (
      <Component
        {...(props as P)}
        isOpen={modalProps.isOpen}
        onClose={() => {
          modalProps?.onClose?.();
        }}
        key={id}
      />
    );
  };

  const showModal = (modalProps: Partial<P> & DefaultProps = {}) => {
    const id = modalProps.id ?? uuidv4();

    return new Promise<OnCloseParameters<P>>(resolve => {
      const props = {
        ...initialProps,
        ...modalProps
      };

      const onCloseModal = (...args: OnCloseParameters<P>) => {
        resolve(args);
        context.hideModal(id);
      };

      const modal = (
        <Component {...(props as P)} isOpen onClose={onCloseModal} key={id} />
      );

      context.showModal(id, modal);
    });
  };

  const hideModal = useCallback(
    (modalId: string) => context.hideModal(modalId),
    [context]
  );

  return [showModal, hideModal, ModalComponent];
};

export default useModal;
