import { useCallback } from 'react';

import { REFERRAL_LOCAL_STORAGE_KEY } from '@app/constants/referal';
import { fetchWrapper } from '@app/utils/fetchWrapper';

export const useGetRfJwtToken = () => {
  const getRfToken = useCallback(async (token: string) => {
    const referralCode = localStorage.getItem(REFERRAL_LOCAL_STORAGE_KEY);

    return await fetchWrapper<{ accessToken: string }>('/api/auth/rf-jwt', {
      method: 'POST',
      body: JSON.stringify({ token, referralCode })
    });
  }, []);

  return getRfToken;
};
