import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from '@web3auth/base';
import { CommonPrivateKeyProvider } from '@web3auth/base-provider';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';

const web3AuthClientId =
  'BNs0EAiD2Rd3F1lTNr715k8d1Hm5hkmql3Uu5PneVDN-OiLfmMWB9Dy4LNlcT1KDNRKyPVhoSGZGEMT_d-rqpd8';

const DISCORD_CLIENT_ID = '1187015479970254958';

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.OTHER,
  chainId: '0x4e454152',
  rpcTarget: 'https://mainnet.aurora.dev',
  displayName: 'Near',
  blockExplorerUrl: 'https://aurorascan.dev',
  ticker: 'NEAR',
  tickerName: 'NEAR'
};

const privateKeyProvider = new CommonPrivateKeyProvider({
  config: { chainConfig }
});

const web3auth = new Web3AuthNoModal({
  clientId: web3AuthClientId,
  privateKeyProvider,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET
});

const openloginAdapter = new OpenloginAdapter({
  adapterSettings: {
    uxMode: 'redirect', // redirect or popup
    loginConfig: {
      discord: {
        verifier: 'ringfence-discord', // Pass the Verifier name here
        typeOfLogin: 'discord', // Pass on the login provider of the verifier you've created
        clientId: DISCORD_CLIENT_ID // Pass on the Discord Client ID here
      },
      jwt: {
        verifier: 'ringfence-otp', // name of the verifier created on Web3Auth Dashboard
        typeOfLogin: 'jwt',
        clientId: web3AuthClientId
      }
    }
  },
  privateKeyProvider
});

web3auth.configureAdapter(openloginAdapter);

export { web3auth, DISCORD_CLIENT_ID };
