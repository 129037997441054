import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { merge } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { useSwipeable } from 'react-swipeable';
import { useLockBodyScroll, useMedia } from 'react-use';

import { useOnClickOutside } from 'src/hooks/useOnClickOutside';

import s from './GenericDropdown.module.scss';
import { BaseDropdownProps } from './types';

export interface GenericDropdownProps extends BaseDropdownProps {
  children: React.ReactElement;
  onOpenUpdate?: (open: boolean) => void;
  forceState?: (open: boolean) => void;
  arrow?: boolean;
  arrowClassName?: string;
  showOnHover?: boolean;
  preventOpenThroughParent?: boolean;
  mobilePanelClassname?: string;
  allowModalNavigation?: boolean;
  forceNotMobile?: boolean;
  offset?: number[];
  strategy?: 'absolute' | 'fixed';
  className?: string;
}

export const GenericDropdown: FC<GenericDropdownProps> = ({
  arrow,
  parent,
  isOpen,
  children,
  options,
  onOpenUpdate,
  arrowClassName,
  showOnHover,
  preventOpenThroughParent,
  mobilePanelClassname,
  allowModalNavigation,
  forceNotMobile,
  offset,
  strategy = 'fixed',
  className
}) => {
  const isMobileView = useMedia('(max-width: 960px)', false);
  const [open, setOpen] = useState(isOpen);
  const [popperElement, setPopperElement] = useState<HTMLElement>();
  const [referenceElement, setReferenceElement] = useState<HTMLElement>();
  const isMobile = !forceNotMobile && isMobileView;

  useLockBodyScroll(isMobile && open);

  function openCloseDropdown(e: unknown, isClickOutside: boolean) {
    if (preventOpenThroughParent && !isClickOutside) {
      return;
    }

    if (showOnHover) {
      return;
    }

    setOpen(!open);

    if (onOpenUpdate) {
      onOpenUpdate(!open);
    }
  }

  useOnClickOutside(
    [
      { current: popperElement } as React.RefObject<HTMLElement>,
      { current: referenceElement } as React.RefObject<HTMLElement>
    ],
    openCloseDropdown,
    allowModalNavigation
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const path = e.composedPath();

      const nextValue =
        path.includes(referenceElement as EventTarget) ||
        path.includes(popperElement as EventTarget);

      if (nextValue !== open) {
        setOpen(nextValue);
      }
    },
    [open, popperElement, referenceElement]
  );

  useEffect(() => {
    if (showOnHover) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove, showOnHover]);

  const opts = merge(
    {
      strategy,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: offset || [0, arrow ? 16 : 0]
          }
        }
      ]
    },
    options
  );

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    opts
  );

  const parentEl = React.cloneElement(parent, {
    ref: setReferenceElement as React.LegacyRef<unknown>,
    onClick: openCloseDropdown
  });

  const swipeHandler = useSwipeable({
    onSwipedDown: () => {
      if (isOpen) {
        setOpen(false);
        onOpenUpdate?.(false);
      }
    }
  });

  function getDropdown() {
    const arrowEl = arrow && (
      <div
        data-popper-arrow
        style={styles.arrow}
        className={cn(s.arrow, arrowClassName)}
        key="arrow"
      />
    );

    if (isMobile)
      return (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.7 }}
            exit={{ opacity: 0 }}
            className={s.overlay}
            onClick={e => openCloseDropdown(e, true)}
          />
          <motion.div
            className={cn(s.mobileDropdown, mobilePanelClassname)}
            // initial={{ opacity: 0, transform: 'translateY(100%)' }}
            animate={{ opacity: 1, transform: 'translateY(0%)' }}
            // exit={{ opacity: 0, transform: 'translateY(100%)' }}
          >
            <div className={s.handle} {...swipeHandler}>
              <div className={s.thumb} />
            </div>
            {children}
          </motion.div>
        </>
      );

    return (
      <div
        ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
        style={styles.popper}
        {...attributes.popper}
        className={cn(s.dropdown, className)}
      >
        <motion.div
          initial={{ opacity: 0, transform: 'translateY(20px)' }}
          animate={{ opacity: 1, transform: 'translateY(-6px)' }}
          exit={{ opacity: 0, transform: 'translateY(20px)' }}
        >
          {arrowEl}
        </motion.div>
        <motion.div
          transition={{ delay: 0, duration: 0.3 }}
          initial={{ opacity: 0, transform: 'translateY(20px)' }}
          animate={{ opacity: 1, transform: 'translateY(0)' }}
          exit={{
            opacity: 0
          }}
        >
          {children}
        </motion.div>
      </div>
    );
  }

  return (
    <>
      {parentEl}
      <AnimatePresence>{open && getDropdown()}</AnimatePresence>
    </>
  );
};
