import { Subscription } from '@app/types/user';
import { fetchWrapper } from '@app/utils/fetchWrapper';

export async function fetchSubscription(
  setSubscription: (rewards: Subscription[]) => void
) {
  const { data, success } = await fetchWrapper<Subscription[]>('/api', {
    method: 'POST',
    body: JSON.stringify({
      opts: {
        method: 'GET',
        url: '/api/credit-system/my-subscriptions'
      }
    })
  });

  if (success && data) {
    setSubscription(data);
  }
}
