import { CreditBalance } from '@app/types/user';
import { fetchWrapper } from '@app/utils/fetchWrapper';

export async function fetchCreditBalance(
  setBalance: (balance: string) => void
) {
  const { data, success } = await fetchWrapper<CreditBalance>('/api', {
    method: 'POST',
    body: JSON.stringify({
      opts: {
        method: 'GET',
        url: '/api/credit-system/my-credit-balance'
      }
    })
  });

  if (success && data) {
    setBalance(data.creditBalance);
  }
}
