import { useCallback, MutableRefObject, RefCallback } from 'react';

type Ref<T> = MutableRefObject<T | null> | RefCallback<T> | null;

export function useMergedRefs<T>(...refs: Ref<T>[]): RefCallback<T> {
  return useCallback(
    (node: T | null) => {
      refs.forEach(ref => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref != null) {
          ref.current = node;
        }
      });
    },
    [refs]
  );
}
