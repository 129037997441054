import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import s from './LinkWrapper.module.scss';

interface LinkWrapperProps {
  href?: string;
  isExternal?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const LinkWrapper: React.FC<LinkWrapperProps> = ({
  href,
  isExternal = false,
  children,
  className,
  onClick,
  ...rest
}) => {
  const rootClass = cn(s.root, className);

  if (isExternal && href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={rootClass}
        onClick={onClick}
        {...rest}
      >
        {children}
      </a>
    );
  }

  if (!isExternal && href) {
    return (
      <Link href={href} className={rootClass} onClick={onClick} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <div className={rootClass} onClick={onClick} {...rest}>
      {children}
    </div>
  );
};
