import cn from 'classnames';
import React, {
  FC,
  memo,
  ReactNode,
  useCallback,
  useState,
  ComponentType,
  useEffect
} from 'react';
import { usePreventScroll } from 'react-aria';
import ReactModal from 'react-modal';

import { Button, Icon } from '@app/ui-kit';

import styles from './Modal.module.scss';

const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal['props']>;

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'auto' | 'fullscreen';
  className?: string;
  hideCloseIcon?: boolean;
  overlayClassName?: string;
  contentClassName?: string;
}

export const Modal: FC<ModalProps> = memo(
  ({
    isOpen,
    onClose,
    children,
    size = 'md',
    className = '',
    hideCloseIcon,
    overlayClassName,
    contentClassName
  }) => {
    const [open, setOpen] = useState(isOpen);

    usePreventScroll({ isDisabled: !open });

    const handleClose = useCallback(() => {
      onClose?.();
    }, [onClose]);

    useEffect(() => {
      if (open) {
        const layout = document.getElementById('core_main_layout');

        if (layout) {
          layout.style.transform = 'scale(0.98)';
        }
      } else {
        const layout = document.getElementById('core_main_layout');

        if (layout) {
          layout.style.transform = 'unset';
        }
      }
    }, [open]);

    return (
      <ModalSafeForReact18
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        isOpen={open}
        onAfterClose={handleClose}
        onRequestClose={() => setOpen(false)}
        className={{
          base: cn(styles.root, className, {
            [styles.sm]: size === 'sm',
            [styles.md]: size === 'md',
            [styles.lg]: size === 'lg',
            [styles.xl]: size === 'xl',
            [styles.xxl]: size === 'xxl',
            [styles.xxxl]: size === 'xxxl',
            [styles.auto]: size === 'auto',
            [styles.fullscreen]: size === 'fullscreen'
          }),
          afterOpen: styles.contentAfterOpen,
          beforeClose: styles.contentBeforeClose
        }}
        overlayClassName={{
          base: cn(styles.overlay, overlayClassName),
          afterOpen: styles.overlayAfterOpen,
          beforeClose: styles.overlayBeforeClose
        }}
        shouldCloseOnEsc
        closeTimeoutMS={300}
      >
        <div className={cn(styles.content, contentClassName)}>
          {!hideCloseIcon && (
            <Button
              view="tertiary1"
              size="large"
              onClick={() => setOpen(false)}
              className={styles.closeWrapper}
            >
              <Icon name="cross" />
            </Button>
          )}
          {children}
        </div>
      </ModalSafeForReact18>
    );
  }
);
