import { InMemoryKeyStore, KeyStore } from '@near-js/keystores';
import { Account, connect, ConnectConfig, KeyPair } from 'near-api-js';

export async function getNearAccount(
  network: string,
  accountId: string,
  privateKey: string
): Promise<Account> {
  const keyStore: KeyStore = new InMemoryKeyStore();

  await keyStore.setKey(network, accountId, KeyPair.fromString(privateKey));

  const config: ConnectConfig = {
    networkId: network,
    keyStore,
    nodeUrl: `https://rpc.${network}.near.org`
  };

  const near = await connect(config);

  return near.account(accountId);
}
