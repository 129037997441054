import { getED25519Key } from '@toruslabs/openlogin-ed25519';
import { KeyPair, utils } from 'near-api-js';

export async function getEncodedInfo(provider: any) {
  const privateKey = await provider.request({
    method: 'private_key'
  });

  // Convert the private key to base58
  const edKey = getED25519Key(privateKey);
  const bs58encode = utils.serialize.base_encode(edKey.sk as any);
  const keyPair = KeyPair.fromString(bs58encode);
  const publicAddress = keyPair?.getPublicKey().toString();
  const accountId = Buffer.from(
    utils.serialize.base_decode(publicAddress.split(':')[1])
  ).toString('hex');

  return {
    accountId,
    publicAddress,
    privateKey: `ed25519:${bs58encode}`
  };
}
