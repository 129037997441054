import { User } from '@app/types/user';
import { fetchWrapper } from '@app/utils/fetchWrapper';

export async function fetchUserProfile(setUser: (user: User) => void) {
  const { data, success } = await fetchWrapper<User>('/api', {
    method: 'POST',
    body: JSON.stringify({
      opts: {
        method: 'GET',
        url: '/api/wallet/me'
      }
    })
  });

  if (success && data) {
    setUser(data);
  }
}
