import React, { FC } from 'react';

import { Icon } from '@app/ui-kit';
import { Modal } from '@app/ui-kit/Modal';

import s from './MintingProcess.module.scss';

interface MintingProcessProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
}

export const MintingProcess: FC<MintingProcessProps> = ({
  isOpen,
  onClose,
  title = 'Minting cNFT',
  text = 'Allow us a moment to move your art onchain'
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseIcon
      className={s.root}
      overlayClassName={s.overlay}
      size="sm"
    >
      <div className={s.image}>
        <Icon name="loadingAlt" className={s.icon} />
      </div>
      <p className={s.title}>{title}</p>
      <p className={s.text}>{text}</p>
    </Modal>
  );
};
