import { createContext } from 'react';

export interface ActivationContextI {
  init: boolean;
  activated: boolean;
}

export const ActivationContext = createContext<ActivationContextI>({
  init: false,
  activated: false
});
